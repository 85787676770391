<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Visor - Actividad Log</h4>
            <div class="small text-muted">Visualiza toda la actividad realizada en el sistema</div>
          </b-col>

          <b-col sm="5">          
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                            
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="3">
            <b-form-group description="Puede filtrar por 'Acción' o 'Tabla'.">
              <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="filter.search"></b-form-input>
            </b-form-group>
          </b-col>            
          <b-col sm="4">
            <b-row>
              <b-col md="6" class="pr-0">
                <b-form-input type="datetime-local" v-model="filter.date_start_tmp" placeholder="Desde"></b-form-input>
              </b-col>
              <b-col md="6" class="pl-0">
                <b-form-input type="datetime-local" v-model="filter.date_end_tmp" placeholder="Hasta"></b-form-input>
              </b-col>              
            </b-row>            
          </b-col>      
          <b-col sm="3">
            <v-select :options="arr.filter.users" v-model="filter.users" placeholder="Usuarios" :multiple="false" :select-on-tab="true"></v-select>
          </b-col>                            
          <b-col sm="2">
            <b-button variant="outline-dark" @click="filterLog()">
              <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
              Filtrar
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-table class="mb-0"
                  responsive="sm"
                  head-variant="dark"
                  :hover="true"
                  :small="true"
                  :fixed="false"
                  :items="table.items"
                  :fields="table.fields"                
                  :current-page="table.currentPage"
                  :per-page="table.perPage"
                  selectable
                  select-mode="single"
                  @row-selected="onRowSelected"                  
                  v-if="table.items.length">
              
              <template v-slot:row-details="row">
                <b-card no-body>
                  <b-tabs content-class="mt-3" v-if="selectedItem">
                    <b-tab title="DATOS">
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="URL">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="selectedItem.info.url"
                                          disabled>
                            </b-form-input>
                          </b-form-group>
                        </b-col>     
                        <b-col md="6">
                          <b-form-group label="ANTES">
                            <json-tree v-if="selectedItem.change.old" :data="selectedItem.change.old" :level="2" class="activity-log-design-pre-json"></json-tree>                  
                            <b-alert variant="info" show v-else>                              
                              No se encontraron movimientos de datos
                            </b-alert>                            
                          </b-form-group>
                        </b-col>                        
                        <b-col md="6">
                          <b-form-group label="AHORA" v-if="row.item.description == 'created' || 
                                                            row.item.description == 'updated' || 
                                                            row.item.description == 'deleted'">
                            <json-tree v-if="selectedItem.change.attributes" :data="selectedItem.change.attributes" :level="2" class="activity-log-design-pre-json"></json-tree>                  
                            <b-alert variant="info" show v-else>                              
                              No se encontraron movimientos de datos
                            </b-alert>                                           
                          </b-form-group>

                          <b-form-group label="AHORA" v-else>
                            <json-tree v-if="selectedItem.properties" :data="selectedItem.properties" :level="2" class="activity-log-design-pre-json"></json-tree>                  
                            <b-alert variant="info" show v-else>                              
                              No se encontraron movimientos de datos
                            </b-alert>                                           
                          </b-form-group>
                        </b-col>                        
                      </b-row>
                    </b-tab>
                    <b-tab title="DISPOSITIVO">
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="IDIOMA">
                            <b-form-input type="text"
                                          size="sm"
                                          v-model="selectedItem.info.lang"
                                          disabled>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="NAVEGADOR">
                            <json-tree v-if="selectedItem.info.browser" :data="selectedItem.info.browser" :level="2" class="activity-log-design-pre-json"></json-tree>                  
                            <b-alert variant="info" show v-else>                              
                              No se encontró información sobre el navegador
                            </b-alert>                                           
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab title="UBICACIÓN">
                      <b-row>
                        <b-col md="6">
                          <b-form-group label="DIRECCIÓN IP">
                            <json-tree v-if="selectedItem.info.ip" :data="selectedItem.info.ip" :level="2" class="activity-log-design-pre-json"></json-tree>                  
                            <b-alert variant="info" show v-else>                              
                              No se encontró información sobre la ip pública
                            </b-alert>                                           
                          </b-form-group>
                        </b-col>
                        <b-col md="6">                          
                          <div class="activity_log_custom_mapa">                                   
                            <l-map          
                              :options="maps.options"
                              :zoom="maps.zoom"
                              :center="maps.center"
                              v-if="maps.urlMaps"
                            >
                              <l-tile-layer :url="maps.url"></l-tile-layer>
                                <l-marker :lat-lng="maps.markerLatLng" />                                  
                            </l-map>                                 
                          </div>                           
                        </b-col>
                      </b-row>                        
                    </b-tab>                    
                  </b-tabs>                  
                </b-card>
              </template>

              <template v-slot:cell(id)="data">
                <b>{{ data.item.id }}</b>
              </template>

              <template v-slot:cell(log_name)="data">
                <b>{{ data.item.log_name }}</b>
              </template>

              <template v-slot:cell(created_at)="data">
                {{ moment(data.item.created_at).format('DD MMMM YYYY HH:mm:ss') }}
              </template>
            
              <template v-slot:cell(description)="data">
                <b-badge variant="info" class="custom-tag-action" v-if="data.item.description == 'created'">
                  {{data.item.description.toUpperCase()}}
                </b-badge>                
                <b-badge variant="warning" class="custom-tag-action" v-if="data.item.description == 'updated'">
                  {{data.item.description.toUpperCase()}}
                </b-badge>
                <b-badge variant="danger" class="custom-tag-action" v-if="data.item.description == 'deleted'">
                  {{data.item.description.toUpperCase()}}
                </b-badge>  

                <b-badge variant="dark" class="custom-tag-action" v-if="data.item.description != 'created' && 
                                                                        data.item.description != 'updated' && 
                                                                        data.item.description != 'deleted'">
                  {{data.item.description.toUpperCase()}}
                </b-badge>                                
              </template>

              <template v-slot:cell(causer)="data">
                <div v-if="data.item.causer">                           
                  <b>
                    {{data.item.causer.name}}                    
                  </b>                       
                  <br>{{data.item.causer.email}}                   
                </div>                             
              </template>
            
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>             
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'

  import Vue from 'vue'
  import JsonTree from 'vue-json-tree'
  Vue.component('json-tree', JsonTree)  
  
  import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
  import { Icon } from 'leaflet'
  import 'leaflet/dist/leaflet.css';  

  delete Icon.Default.prototype._getIconUrl

  Icon.Default.mergeOptions({    
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [25, 25 * 1.15],
    iconAnchor: [25 / 2, 25 * 1.15],    
    shadowSize: [25, 25 * 1.15],
    shadowAnchor: [25 / 2, 25 * 1.15],        
  })

  export default {
    components: { 
      LMap,
      LTileLayer,
      LMarker,   
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.ACTIVIDAD_LOG,
          profile_id: Profiles.PERSONAL,
          view_reference: 'viewer',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: "align-middle"},            
            {key: 'description', label: 'Acción', class: "align-middle text-left"},
            {key: 'created_at', label: 'Fecha', class: "align-middle text-left"},            
            {key: 'log_name', label: 'Tabla', class: "align-middle text-left"},
            {key: 'causer', label: 'Responsable', class: "align-middle text-left"},            
          ],          
          mostrarFiltros: true,  
          currentPage: 1,
          perPage: 50,
        },
        arr: {
          activity: [],
          filter: {
            users: [],
          }
        },
        filter: {
          date_start_tmp: '',
          date_end_tmp: '',
          date_start: '',
          date_end: '',
          users: null,
          search: '',
        },    
        maps: {
          urlMaps: '',
          url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
          options: {zoomControl: false, zoomSnap: true},          
          zoom: 14,
          center: [],
          markerLatLng: [],                  
        },           
        selectedItem: null,
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)    
      /* Fin configuracion */
    },  
    mounted() {                      
      this.loadFilter()
      this.filterLog()
    },
    methods: {     
      configViewerJson() {    
        setTimeout(()=>{
          var arrElement = document.getElementsByClassName("json-tree-indent");        
          if(arrElement) {
            for (var i = 0; i < arrElement.length; i++){
              if(arrElement[i].innerHTML != '&nbsp;') {
                arrElement[i].innerHTML = '&nbsp;';            
              }                        
            }                              
          }
        },500)                    
      },

      getRowCount (items) {
        return items.length
      },      
      onRowSelected(item) {
        this.arr.activity.forEach((element, index) => {  
          if(item.length) {              
            if(element.id == item[0].id) {

              if(this.table.items[index]._showDetails) {
                this.table.items[index]._showDetails = false
                this.selectedItem = null
              } else {
                this.openDetail(index)
                this.filterByID(item[0].id)
              }                       

            }
          } else {
            this.table.items[index]._showDetails = false
          }         
        })
      },
      openDetail(index) {
        this.table.rowSelected = index        
        this.filterLog()
      },

      loadFilter() {
        this.filter.date_start_tmp = moment().subtract(15, 'minutes').format('YYYY-MM-DDTHH:mm') 
        this.filter.date_end_tmp = moment().format('YYYY-MM-DDTHH:mm')

        this.loadFilterUser()
      },
      loadFilterUser() {
        var result = serviceAPI.getAllUsers()        
        result.then((response) => {
          var data = response.data  
         
          this.arr.filter.users = []
          data.forEach(element => {            
            this.arr.filter.users.push({ code: element.id, label: element.name })            
          });          
        })               
      },
      filterLog(){
        let loader = this.$loading.show()   
   
        this.filter.date_start = moment(this.filter.date_start_tmp).format('YYYY-MM-DD HH:mm:ss')
        this.filter.date_end = moment(this.filter.date_end_tmp).format('YYYY-MM-DD HH:mm:ss')

        var result = serviceAPI.filtrarActivityLog(this.filter)        
        result.then((response) => {
          loader.hide()

          var data = response.data
          this.table.items = data         
          this.arr.activity = data 

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true                        
          }          
        })
        .catch(error => {          
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });          
      },  
      filterByID(id) {
        let loader = this.$loading.show()   
   
        var result = serviceAPI.filtrarActivityLogById(id)        
        result.then((response) => {
          loader.hide()

          var data = response.data
          this.selectedItem = data       
          this.configViewerJson()

          setTimeout(()=>{
            this.loadMaps()
          },100)          
        })
        .catch(error => {               
          loader.hide()
          this.selectedItem = null
          this.$awn.alert(Error.showError(error))
        });  
      },     

      loadMaps() {     
        this.maps.urlMaps = ''
        this.maps.center = []
        this.maps.markerLatLng = []

        if(this.selectedItem.info) {      
          if(this.selectedItem.info.ip) {
            if(this.selectedItem.info.ip!='127.0.0.1') {

              if(this.selectedItem.info.ip.latitude && this.selectedItem.info.ip.longitude ) {                    
                var data = this.selectedItem.info.ip

                this.maps.urlMaps = 'https://www.openstreetmap.org/#map=17/' + parseFloat(data.latitude) + '/' + parseFloat(data.longitude)
                this.maps.center = [parseFloat(data.latitude), parseFloat(data.longitude)]
                this.maps.markerLatLng = [parseFloat(data.latitude), parseFloat(data.longitude)]                  
              }

            }
          }
        }       
      }
    }    
  }
</script>
<style scoped>
  .custom-tag-action {
    font-size: 12px;
  }
  .activity_log_custom_mapa {  
    padding: 0px;
    height: 300px;
  }
</style>
<style>
  .activity-log-design-pre-json {
    overflow: auto;    
    height: 300px;
    width: 550px;
    max-width: 550px;
    min-width: unset;
  }  
  .json-tree-value {
      overflow: unset;
      text-overflow: unset;
      white-space: nowrap;
  }  
  .json-tree-collapsed {
      overflow: unset;
      text-overflow: unset;
      white-space: nowrap;    
  }  
</style>