import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  filtrarActivityLog(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "activity-log" + Session.getToken();
    return instance.post(url,params);
  },    
  filtrarActivityLogById(id) {      
    var url = ConfigAPI.baseURL + "activity-log/" + id + Session.getToken();
    return instance.get(url);
  },    

  getAllUsers() {           
    var url = ConfigAPI.baseURL + "users" + Session.getToken();
    return instance.get(url);
  },    
}

export default servicesAPI;
